<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@suaw/suaw-component-library";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "HowToMakeFriendsWithChatGpt",
  components: {
    SuawMainContent,
    SuawParagraph,
    // eslint-disable-next-line vue/no-unused-components
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="HowToMakeFriendsWithChatGpt">
      <SuawParagraph text="ChatGPT did not write this article." />
      <SuawParagraph
        text="You’re probably aware that artificial intelligence, or AI, is making headlines. ChatGPT, an artificial intelligence chatbot created by OpenAI, was released in November 2022 and now has over one hundred million users using its free services. ChatGPT can not only converse with a user intelligently, it can write code for software, create a business pitch, and most importantly, write content in any style or on any topic. And yes, it’s even capable of writing articles about itself, which begs the question: Why am I bothering to write this article without its help?"
      />
      <SuawParagraph text="Well, because I can.&nbsp;" />
      <SuawParagraph
        text="Writers everywhere, particularly professional writers, may be feeling more than a little bit nervous about ChatGPT. We’ve all been warned that someday robots will rise up and take our jobs, and now, the future is here. ChatGPT is perfectly capable of writing marketing copy that ranges from serviceable to downright inspired. It can certainly whip up a decent article on any topic imaginable, or write software code. Students know very well that it can produce a paragraph–if not an entire essay–from thin air, much to the horror of professors everywhere.&nbsp;"
      />
      <SuawParagraph
        text="But like many technological advancements, something that seems unnervingly futuristic can ultimately prove to be helpful. Will jobs be eliminated? Possibly. Will human writers continue to be necessary as AI bots churn out essays, scripts, and even novels? Absolutely."
      />
      <SuawParagraph
        text="You can buy a dining room table from IKEA, but IKEA hasn’t eliminated the craft of woodworking. Photography hasn’t caused painting to die out, and factories haven’t caused pottery to disappear. In the same way, AI chatbots won’t eliminate the craft of writing. Writing is both a craft and an art, and just as there are distinct uses for both a handmade rosewood cabinet and an IKEA “KALLAX” bookshelf, there are also distinct uses for both human-crafted writing and artificially generated text."
      />
      <SuawParagraph
        text="More than that, writers can learn to leverage ChatGPT as a tool. It may seem intimidating (or worse, dishonest) to consider using an AI chatbot as an aid for your writing project. However, most writers don’t think twice about using a search engine, a calculator, a note-taking app, or even a laptop, and those are technological tools as well. ChatGPT may be a powerful piece of software, but that doesn’t mean you should be afraid of giving it a try. If you can type a search term into a website, you can easily use ChatGPT. And once you get used to it, ChatGPT can become just another tool in your writing arsenal."
      />
      <SuawParagraph
        text="So if you’re ready to meet your new writing assistant, create a free&nbsp;<a target='_blank' title='OpenAI's ChatGPT' href='https://chat.openai.com/chat'><u>OpenAI</u></a> account. But with such a wide range of possible questions to ask, how do you leverage ChatGPT to help with a writing project? Here are just a few ideas."
      />
      <SuawParagraph
        text="<strong>Character Development:&nbsp;</strong>If you’re writing fiction and you’re struggling to fill a scene with secondary characters, turn to ChatGPT for a list of potential extras.&nbsp;"
      />
      <SuawParagraph text="<em>Me: Give me a list of 5 characters who might appear in a science fiction novel with a Western twist.</em>" />
      <SuawParagraph text="<em>ChatGPT:</em>" />
      <SuawParagraph text="<em>1. The Gunslinger - A skilled and deadly shooter who is a master of both traditional guns and high-tech laser weapons.</em>" />
      <SuawParagraph text="<em>2. The Outlaw - A rogue who is on the run from the law, but possesses advanced technology that gives them an edge over their pursuers.</em>" />
      <SuawParagraph
        text="<em>3. The Cyborg - A human with advanced cybernetic implants that give them enhanced strength, speed, and reflexes, making them a formidable opponent in any battle.</em>"
      />
      <SuawParagraph
        text="<em>4. The Saloon Keeper - A savvy businessperson who runs a popular establishment that serves as a hub for travelers passing through the frontier.</em>"
      />
      <SuawParagraph
        text="<em>5. The Alien - A member of an extraterrestrial species that has come to Earth in search of adventure, knowledge, or perhaps just a good drink.</em>"
      />
      <SuawParagraph
        text="Now, not all of these ideas may inspire you, but there’s a good chance that something ChatGPT suggests might spark a creative ember. I’m personally a big fan of the Saloon Keeper character – at least, that idea made me smile. So if I want to continue the conversation, I can ask ChatGPT questions about that specific character, and it will remember our previous exchanges when it answers. (Incidentally, it suggested that I name the Saloon Keeper “Jedediah Kane.” Not bad.)"
      />
      <SuawParagraph
        text="<strong>Worldbuilding:&nbsp;</strong>Writers who enjoy crafting new worlds from scratch may really bond with ChatGPT. Let’s say I’m working on a medieval fantasy setting, and I’m fresh out of ideas for the towns in the neighboring kingdom. ChatGPT to the rescue."
      />
      <SuawParagraph text="<em>Me: Give me five ideas for towns in a medieval fantasy setting.</em>" />
      <SuawParagraph text="<em>ChatGPT:</em>" />
      <SuawParagraph
        text="<em>1. Stonehaven: A fortress town built into the side of a towering mountain range, Stonehaven is known for its formidable walls, skilled blacksmiths, and its rich veins of iron and other precious minerals. Despite its rugged appearance, the town is surprisingly prosperous, with a bustling market and plenty of opportunities for those willing to take on the challenges of living in such a harsh and unforgiving environment.</em>"
      />
      <SuawParagraph
        text="<em>2. Mistwood: Located deep in a dense forest, Mistwood is a town renowned for its archers, who are said to be the finest in the realm. The town is also home to a group of druids, who are rumored to possess magical powers and to have a deep understanding of the natural world. Many travelers come to Mistwood seeking guidance from the druids, or to purchase the finely crafted bows and arrows that are produced by the town's skilled artisans.</em>"
      />
      <SuawParagraph
        text="<em>3. Silverwater: Situated on the banks of a large river, Silverwater is a bustling trade town that serves as a hub for merchants traveling between the kingdoms. The town is known for its fine silks, spices, and other exotic goods, and its markets are always crowded with travelers and locals alike. However, the town also has a darker side, as rumors persist of smugglers and thieves who take advantage of the town's strategic location to engage in illicit activities.</em>"
      />
      <SuawParagraph
        text="<em>4. Stormwatch: Built atop a rocky promontory overlooking the sea, Stormwatch is a town that lives up to its name, with fierce gales and pounding waves that batter the coast year-round. The town is home to a hardy population of fishermen and sailors, who venture out into the treacherous waters in search of the abundant fish and other sea creatures that inhabit the area. However, the town is also vulnerable to raids by pirates and other seafaring brigands, who are drawn to the rich pickings to be had from attacking ships in the region.</em>"
      />
      <SuawParagraph
        text="<em>5. Sunflower: Located in the heart of a fertile plain, Sunflower is a town known for its bountiful harvests and pastoral beauty. The town's farmers are renowned for their skill at cultivating crops, and their produce is prized throughout the land. However, the town's idyllic appearance belies a simmering conflict between the farmers and the nearby nobility, who covet the rich lands and resources of the region and seek to assert their dominance over the town and its people.</em>"
      />
      <SuawParagraph
        text="Okay, so maybe the names are unoriginal, and in some cases, bizarre. (“Sunflower?”) And maybe most of the ideas are generic and trite. But by sifting through this output of cliches and tropes, you might find a few gems. A conflict between farmers and nobility? That might have potential. ChatGPT, tell me more about this storm brewing in Sunflower… but let’s change that name, okay?"
      />
      <SuawParagraph
        text="<strong>Plot Wrangling:</strong> ChatGPT’s true strength is its ability to generate ideas. Are you stuck at a particular plot point in your story with no clear way to move forward? Tell ChatGPT that you will input your plot, and then copy and paste it into the search field. Then, ask ChatGPT to come up with a few ideas for future plot points, given your existing story. ChatGPT will remember the information you’ve given it and use that information to give you inspiration for next steps."
      />
      <SuawParagraph
        text="<em>Me: In my story, the protagonist is struggling with romantic feelings for his barista. He's also trying to bust an international crime ring. What should happen next?</em>"
      />
      <SuawParagraph text="<em>ChatGPT: There are a few different directions you could take the story, but here are a few possibilities to consider:</em>" />
      <SuawParagraph
        text="<em>1. The protagonist could continue to struggle with his feelings for the barista while also working to bust the crime ring. This could create tension and potentially lead to mistakes or oversights in his investigation. Alternatively, the barista could become involved in the investigation in some way, potentially putting her in danger and making the protagonist's feelings for her even more complicated.</em>"
      />
      <SuawParagraph
        text="<em>2. The protagonist could realize that his feelings for the barista are a distraction from his investigation and try to distance himself from her. This could create emotional turmoil for both characters, especially if the barista is also interested in the protagonist.</em>"
      />
      <SuawParagraph
        text="<em>3. The protagonist could discover that the crime ring is somehow connected to the barista or her workplace. This could add a new layer of complexity to the story and force the protagonist to confront both his feelings for the barista and the dangerous world he's trying to navigate.</em>"
      />
      <SuawParagraph
        text="<em>Ultimately, the direction you choose will depend on the tone and themes of your story, as well as what you want to convey about your characters and their motivations. Consider what will create the most interesting and engaging conflict for your readers, while also staying true to your characters' personalities and goals.</em>"
      />
      <SuawParagraph
        text="That last paragraph was ChatGPT, by the way. Not only did it generate a few plot ideas, but it then provided a few words of wisdom about writing in general. Again, its ideas aren’t the most original, but sometimes any idea will help you break out of a tight corner. And if you want to find out what happens next, you can pick one of these options and continue to follow up with ChatGPT. It’s almost like creating a Choose-Your-Own-Adventure story."
      />
      <SuawParagraph
        text="<strong>Research:</strong> Not only can you ask ChatGPT for a list of ideas, you can also ask ChatGPT to be your personal teacher. Go one step further than a search engine by asking ChatGPT to tell you about the Spanish Civil War, the history of automobiles, or the top 20 songs of the last 10 years. Sure, you can use Google for that, but ChatGPT will summarize and process the information in a way that makes it even faster and easier to understand.&nbsp;"
      />
      <SuawParagraph text="<em>Me: I'm writing a murder mystery. How would the symptoms of arsenic poisoning be mistaken for another ailment?</em>" />
      <SuawParagraph
        text="<em>ChatGPT: The symptoms of arsenic poisoning can be similar to other illnesses, such as gastroenteritis, food poisoning, meningitis, encephalitis, asthma, and drug overdose. For example, abdominal pain, nausea, and vomiting can be symptoms of food poisoning, while headache, confusion, and drowsiness can be symptoms of meningitis or encephalitis.</em>"
      />
      <SuawParagraph
        text="Muscle cramps and weakness can be mistaken for muscle strains or spasms, while respiratory distress and failure can be symptoms of asthma or other respiratory diseases. The symptoms of arsenic poisoning can also be delayed, taking several hours or days after exposure to arsenic for symptoms to appear. This delay can make it difficult to identify the cause of the illness."
      />
      <SuawParagraph
        text="Furthermore, some symptoms of arsenic poisoning, such as confusion and convulsions, can also occur with certain drugs. This can make it challenging to determine whether the illness is the result of arsenic poisoning or drug overdose."
      />
      <SuawParagraph
        text="In a murder mystery, the possibility that the symptoms of arsenic poisoning may be mistaken for another ailment or cause of death should be taken into account. Forensic analysis of blood and tissue samples is typically required to identify the presence of arsenic in the body and determine the cause of death."
      />
      <SuawParagraph text="And there you have it. Thank you, Dr. Watson.&nbsp;" />
      <SuawParagraph
        text="As a side note, be sure to check your facts if you’re using ChatGPT in this way. While it can be extremely accurate, it can also suffer from the occasional&nbsp;<a target='_blank' title='What Are Hallucinations and Why Are They a Problem for AI Systems?' href='https://bernardmarr.com/chatgpt-what-are-hallucinations-and-why-are-they-a-problem-for-ai-systems/'><u>hallucination</u></a>. Just as Google Maps sometimes doesn’t know about roadblocks, ChatGPT isn’t perfect, and it can end up with incorrect information.&nbsp;"
      />
      <SuawParagraph
        text="ChatGPT’s answers can also be trite – the most obvious, consistently used tropes or concepts. This is ChatGPT’s job, after all: to offer up the most universally popular solutions. It’s up to you, as a writer, to sift through these ideas to find the gems, or to use ChatGPT as a springboard to come up with something even more interesting. If you know that ChatGPT always takes the well-trodden path, see what it has to say and then run in the opposite direction."
      />
      <SuawParagraph
        text="That’s why the rise of AI chatbots illuminates the most important unsung job of a writer: the maker of decisions. A writer constantly makes decisions while writing, from plot points and character development to setting and story arc. Who killed the barista? ChatGPT might offer a few suggestions, but ultimately it will refuse to decide for you. Did you want to name that neighboring town “Sunflower” after all? That particular call will be up to you. With a tool like ChatGPT at your disposal, your ability to make editorial decisions takes center stage. ChatGPT can help alleviate some of the pressure to come up with every idea from scratch, and instead launch your brainstorming to the next level so you can focus on what’s really important. Will Jedediah Kane be able to defeat the Cyborg who just walked into his saloon?"
      />
      <SuawParagraph text="ChatGPT may be able to generate ideas, but you, the writer, have all the answers." />
    </ArticleFrame>
  </SuawMainContent>
</template>
